$hero-blue: #4F97D1;
$hero-button-active: #1B1B1B;
$hero-button-default: #1A4480;
$hero-button-hover: #162E51;

// Standards page status buttons and card tops
$research: #0C78CF;
$draft: #1865A3;
$pending: #244C6C;
$required: #1F303E;
